import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"

const PlayAndroid = () => {
  const product = "Big Brain Energy"
  const app_type = "android"
  const submitUrl = "https://submit-form.com/MKYKSK9sQ"

  return (
    <Layout>
      <div>
        <div className="max-w-lg mx-auto p-6 mt-2 bg-white shadow-md rounded-md">
          <div className="flex flex-col gap-[12px] mb-6">
            <h1 className="text-[#141414] text-[24px] font-semibold tracking-[-0.8px]">
              Sorry, {product} apps are not quite ready yet.
            </h1>
            <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
              We’re just putting the finishing touches to the app and will be
              releasing it in a few weeks. But try out our web beta version in
              the meantime!
            </p>
            <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
              Enjoy the game! 🕹️
            </p>
            <Link to="https://play.bbenergy.app/">
              <button className="mt-4 w-full p-2 text-white bg-[#DB28A9] rounded-[8px] font-semibold font-general-sans-semibold">
                Go to web version
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PlayAndroid
